<script>
import NewsFeedsService from "@/services/NewsFeedsService";
import helperService from "@/services/HelperService";
import GroupService from "@/services/GroupService";

export default {
  name: "AddNewsFeedsModal",
  props: {
    group: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      submitting: false,
      feeds: [],
      selectedFeeds: [],
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "url", label: "URL" },
        { key: "actions", label: "Add", class: "text-right" },
      ],
      currentPage: 1,
      total_count: 0,
      hasSearched: false,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
        not_in_group: this.group.uid,
      },
    };
  },
  mounted() {
    this.getNewsFeeds();
  },
  methods: {
    async getNewsFeeds() {
      try {
        const { data } = await NewsFeedsService.listNewsFeeds(this.filters);
        this.feeds = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
        if (this.filters.search) {
          this.hasSearched = true;
        }
      } catch (error) {
        helperService.showNotfyErr(this.$toast, "Error fetching news feed, please refresh and try again.")
      }
    },
    getMoreFeeds(page) {
      this.filters.page = page - 1;
      this.getNewsFeeds();
    },
    async addNewsFeedsToGroup() {
      try {
        if (this.submitting){
          return;
        }
        this.submitting = true;
        const dto = {
          news_feed_ids: this.selectedFeeds,
        }
        await GroupService.addNewsFeeds(this.group.uid, dto)
        this.$emit("refresh");
      } catch (error) {
        helperService.showNotfyErr(this.$toast, "Error adding news feeds to group, please try again.")
      } finally {
        this.submitting = false;
      }
    },
    onCheckboxClick(feedId) {
      this.selectedFeeds = this.selectedFeeds.includes(feedId)
        ? this.selectedFeeds.filter(id => id !== feedId)
        : [...this.selectedFeeds, feedId];
    },
    clearSearch() {
      this.filters.search = null;
      if (!this.hasSearched) {
        return;
      }
      this.getMoreFeeds(1);
    },
    selectAll() {
      for(const feed of this.feeds) {
        if(!this.selectedFeeds.includes(feed.id)) {
          this.selectedFeeds.push(feed.id)
        }
      }
    },
    deselectAll() {
      this.selectedFeeds = []
    },
  }
};
</script>

<template>
  <b-form-group id="add-news-feeds-modal">
    <div class="m-1">
      <label>Search for News Feeds</label>
      <b-input-group>
        <b-form-input
          v-model="filters.search"
          class="d-inline-block"
          placeholder="Search feed name..."
          @keydown.enter="getMoreFeeds(1)"
        />
        <b-input-group-append v-if="filters.search">
          <b-button
            variant="outline-secondary"
            @click="clearSearch"
          >
            Clear
          </b-button>
        </b-input-group-append>
        <b-input-group-append>
          <b-button @click="getMoreFeeds(1)">
            Search
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="mb-1 mx-1 float-right">
      <b-btn v-if="selectedFeeds.length > 0" variant="outline-secondary" size="sm" class="mr-1" @click="deselectAll">Deselect All</b-btn>
      <b-btn v-if="feeds.length > 0" variant="outline-secondary" size="sm" @click="selectAll">Select Page</b-btn>
    </div>

    <b-table
      class="data-table mb-1"
      hover
      :items="feeds"
      :fields="fields"
      fixed
    >
      <template #cell(name)="data">
        <div v-b-tooltip.hover="data.item.name" class="multiline-ellipsis">
          {{ data.item.name }}
        </div>
      </template>
      <template #cell(url)="data">
        <div v-b-tooltip.hover="data.item.url" class="multiline-ellipsis">
          {{ data.item.url }}
        </div>
      </template>
      <template #cell(actions)="data">
        <b-form-checkbox
          :checked="selectedFeeds.includes(data.item.id)"
          group
          class="float-right my-1"
          @change="onCheckboxClick(data.item.id)"
          @click.native.stop
        />
      </template>
    </b-table>

    <b-row v-if="total_count > filters.page_size" class="d-flex flex-row-reverse mb-1 mx-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="total_count"
        :per-page="filters.page_size" class="my-0"
        @change="getMoreFeeds"
      />
    </b-row>

    <b-row v-if="!feeds.length" class="mx-1">
      <b-col cols="12" >
        <p>No feeds available...</p>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end mx-1">
      <b-btn class="mr-1" @click="$emit('close')">Cancel</b-btn>
      <b-btn
        variant="primary"
        :disabled="!selectedFeeds.length || submitting"
        @click="addNewsFeedsToGroup"
      >
        Add Feeds
      </b-btn>
    </b-row>
  </b-form-group>
</template>

<style scoped>
.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
