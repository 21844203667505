<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center" v-if="loaded">
    <h3>{{title}}</h3>
    <h4 v-if="subtitle" class="font-weight-bolder my-2">{{subtitle}}</h4>

    <p v-if="note">{{note}}</p>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :variant="okVariant" @click="$emit('ok', true)">{{submitBtnText}}</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
  export default {
    components: {},
    props: {
      title: {
        required: true,
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      subtitle: {
        required: false,
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      note: {
        required: false,
        type: String,
      },
      okVariant: {
        required: false,
        type: String,
        default: 'danger',
      },
      submitBtnText: {
        required: false,
        type: String,
        default: 'Ok',
      },
    },
    data() {
      return {
        loaded: false, // used to change color variant as bootstrap does not update after mount
      };
    },
    mounted() {
      setTimeout(() => {
        this.loaded = true;
      });
    },
    methods: {

    },
  };
</script>
