<template>
  <b-card-actions ref="groupApplicationsCard" action-collapse title="Applications" class="" no-body>
    <b-card-body>
      Applications with the Auth flag shows theat the applications is alloed to communicate to the MDM solution and log
      it's activities. The Account flag shows applications that provisioned with a Purple Account so that they can log
      in to a third party service.
    </b-card-body>
    <section class="">
      <b-table
        class="data-table"
        hover
        :items="loading ? [] : applications"
        :fields="headers"
        :responsive="true"
      >
        <template #cell(auto_update_mode)="data">
          {{ data.item.auto_update_mode.replace('AUTO_UPDATE_', '') }}
        </template>
        <template #cell(flags)="data">
          <label class="d-inline-block" style="min-width: 55px">
            <i :class="data.item.enable_mdm_auth ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'" class="fas" />
            Auth
          </label>
          <label class="d-inline-block" style="min-width: 80px">
            <i :class="data.item.purple_account_enabled ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'" class="fas " />
            Account
          </label>
        </template>
        <template #cell(featured)="data">
          <b-checkbox v-model="data.item.featured" switch @change="toggleFeatured(data.item)" />
        </template>
      </b-table>
      <b-row v-if="applications.length === 0">
        <b-col class="ml-2 mt-1">
          <p>{{ loading ? 'Loading...' : 'No results' }}</p>
        </b-col>
      </b-row>
    </section>
  </b-card-actions>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import FeaturedAppsService from "@/services/FeaturedAppsService";

  export default {
    components: {
      BCardActions,
    },
    props: {
      group: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        loading: true,
        togglingFeatured: false,
        applications: [],
        featuredApps: [],
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'auto_update_mode',
            label: 'Auto Update',
          },
          {
            key: 'default_permission_policy',
            label: 'Permission Policy',
          },
          {
            key: 'flags',
            label: 'Flags',
          },
          {
            key: 'featured',
            label: 'Featured',
          },
        ],
      };
    },
    mounted() {
      this.applications = this.group.applications;
      this.getFeaturedApps();
    },
    methods: {
      async getFeaturedApps() {
        try {
          const { data } = await FeaturedAppsService.list(this.group.uid);
          this.featuredApps = data;
          for (const application of this.applications) {
            for (const featured of this.featuredApps) {
              if (application.package_name === featured.package_name) {
                application.featured = true;
              }
            }
          }
        } catch (e) {
          //console.error("Error getting featured apps: ", e);
          this.$toast.error("Error getting featured apps");
        } finally {
          this.loading = false;
        }
      },
      async toggleFeatured(item) {
        try {
          if (this.togglingFeatured) {
            return;
          }
          this.togglingFeatured = true;
          if (item.featured) {
            await FeaturedAppsService.create(
              this.group.uid,
              {package_name: item.package_name},
            );
          } else {
            const app = this.featuredApps.find(f => f.package_name === item.package_name);
            await FeaturedAppsService.delete(this.group.uid, app.id)
          }
          await this.getFeaturedApps();
        } catch (e) {
          //console.error("Error toggling featured app: ", e);
        } finally {
          this.togglingFeatured = false;
        }
      }
    },
  };
</script>
