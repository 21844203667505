<template>
  <div class="modal-add-channel">
    <div>
      <b-form-group>

        <!-- Search -->
        <div class="mb-2">
          <label>Search for Purple Play channels</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Enter channel name..."
              @keydown.enter="getMoreChannels(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreChannels(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <section>
          <b-table
            class="data-table"
            hover
            :items="channels"
            :fields="headers"
            :responsive="true"
          >
            <template #cell(image_url)="data">
              <b-avatar
                size="40"
                variant="light-primary"
                :src="data.item.image_url"
                class="badge-minimal"
              />
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(channel_id)="data">
              <a :href="data.item.channel_id | formatYoutubeChannelUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
                {{ data.item.channel_id }}
              </a>
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                variant="primary"
                @click.stop="addChannel(data.item.id)"
              >
                Add
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col v-if="total_count > filters.page_size" md="6" class="my-1">
              <b-pagination v-model="currentPage" :total-rows="total_count" :per-page="filters.page_size" class="my-0" @change="getMoreChannels" />
            </b-col>
            <b-col v-if="total_count === 0" class="ml-2 mt-1">
              <p>No results</p>
            </b-col>
          </b-row>
        </section>

        <b-input-group class="d-flex flex-row-reverse">
          <b-btn class="d-inline" @click="$emit('close')">Cancel</b-btn>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import groupService from '@/services/GroupService';
import channelService from '@/services/YoutubeChannelService';
import { BAvatar } from 'bootstrap-vue';

export default {
  name: 'AddChannelModal',
  components: { BAvatar },
  props: {
    uid: {
      required: true,
      type: String,
    },

  },
  data() {
    const notInGroup = this.uid
    return {
      channels: [],
      selectedChannels: [],
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        not_in_group: notInGroup,
      },
      currentPage: 1,
      total_count: 0,
      headers: [
        {
          key: 'image_url',
          label: 'Image',
        },
        {
          key: 'name',
          label: 'Channel Name',
        },
        {
          key: 'channel_id',
          label: 'Channel ID',
        },
        {
          key: 'actions',
          label: 'Add',
          class: 'text-right',
        },
      ],
    }
  },
  mounted() {
    this.getChannels();
  },
  methods: {
    getMoreChannels(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getChannels();
    },
    async getChannels() {
      try {
        const { data } = await channelService.getChannels(this.filters)
        this.channels = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get available channels, please refresh and try again')
      }
    },
    async addChannel(channelId) {
      try {
        const channelIdsDto = { youtube_channel_ids: [ channelId ] }
        await groupService.addYoutubeChannels(this.uid, channelIdsDto)
        helperService.showNotfySuccess(this.$toast, 'Channel successfully added.')
        await this.getMoreChannels(1)
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not add channel, please refresh and try again')
      } finally {
        this.$emit('refresh')
      }
    },
  },
}
</script>

<style scoped>

</style>
