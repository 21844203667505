<template>
  <div class="group-channels-page">
    <b-card-actions ref="groupChannelsCard" :no-actions="true" title="Purple Play Channels" no-body>
      <b-card-body class="d-flex justify-content-between pb-0">
        This table shows the Purple Play channels the group has access to.
        <div class="text-right mb-1">

          <b-btn variant="primary" class="mx-1" @click="showGroupModal('modal-add-channels')">Add Channels</b-btn>

          <b-dropdown id="dropdown-channels" variant="secondary" right text="Actions">
            <b-dropdown-group id="dropdown-channels-1" header="Channel Actions">
              <b-dropdown-item @click="showGroupModal('modal-add-all-channels')">
                Add All
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="showGroupModal('modal-remove-all-channels')">
                Remove All
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>

        </div>
      </b-card-body>

      <section>
        <b-table
          class="data-table"
          hover
          :items="channels"
          :fields="headers"
          :responsive="true"
        >

          <template #cell(image_url)="data">
            <b-avatar
              size="40"
              variant="light-primary"
              :src="data.item.image_url"
              class="badge-minimal"
            />
          </template>

          <template #cell(name)="data">
            <RouterLink :to="{name: 'purple-play-channel-details', params: {id: data.item.id}}">
              {{ data.item.name }}
            </RouterLink>
          </template>

          <template #cell(channel_id)="data">
            <a :href="data.item.channel_id | formatYoutubeChannelUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
              {{ data.item.channel_id }}
            </a>
          </template>

          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="danger"
              @click.stop="removeChannel(data.item.id)"
            >
              Remove
            </b-button>
          </template>

        </b-table>
        <b-row v-if="channels.length === 0">
          <b-col class="ml-2 mt-1">
            <p>No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card-actions>

    <b-modal v-if="group" id="modal-add-channels" title="Add Purple Play Channels" hide-footer size="lg">
      <add-channel-modal
        title="Add Purple Play Channels"
        :uid="group.uid"
        ok-variant="success"
        @close="closeModals()"
        @refresh="getChannels()"
      />
    </b-modal>

    <b-modal id="modal-add-all-channels" title="Add Purple Play Channels" hide-footer>
      <warning-modal
        title="Are you sure you wish to add all Purple Play channels?"
        @close="closeModals()"
        @ok="addAllChannels()"
      />
    </b-modal>

    <b-modal id="modal-remove-all-channels" title="Remove All Channels" hide-footer>
      <warning-modal
        title="Are you sure you wish to remove all channels?"
        @close="closeModals()"
        @ok="removeAllChannels()"
      />
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import groupService from '@/services/GroupService';
import helperService from '@/services/HelperService';
import AddChannelModal from '@/views/groups/viewGroup/sections/viewChannels/AddChannelModal.vue';
import { BAvatar } from 'bootstrap-vue';
import WarningModal from '@/components/modals/WarningModal.vue';

export default {
  components: {
    WarningModal,
    BAvatar,
    AddChannelModal,
    BCardActions,
  },
  props: {
    group: {
      required: true,
      type: Object,
    },
  },
  data() {
    const headers = [
        {
          key: 'image_url',
          label: 'Thumbnail',
        },
        {
          key: 'name',
          label: 'Channel Name',
        },
        {
          key: 'channel_id',
          label: 'Channel ID',
        },
      ]
    if (this.$can('Update', 'Group')) {
      headers.push({
        key: 'actions',
        label: 'Remove',
        class: 'text-right',
      });
    }
    return {
      headers,
      channels: [],
    };
  },
  mounted() {
    this.getChannels()
  },
  methods: {
    refreshChannels() {
      this.closeModals();
      this.getChannels();
    },
    async getChannels() {
      try {
        const { data } = await groupService.getYoutubeChannels(this.group.uid)
        this.channels = data;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get channels, please refresh and try again')
      }
    },
    async removeChannel(channelId) {
      try {
        const channelIdsDto = { youtube_channel_ids: [ channelId ] }
        await groupService.removeYoutubeChannels(this.group.uid, channelIdsDto)
        helperService.showNotfySuccess(this.$toast, 'Channel successfully removed.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while trying to remove channels. Please refresh the page and try again.')
      } finally {
        await this.getChannels()
      }
    },
    async removeAllChannels() {
      try {
        await groupService.removeAllYoutubeChannels(this.group.uid)
        helperService.showNotfySuccess(this.$toast, 'Channels have been successfully removed.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while trying to remove channels. Please refresh the page and try again.')
      } finally {
        this.refreshChannels()
      }
    },
    async addAllChannels() {
      try {
        await groupService.addAllYoutubeChannels(this.group.uid)
        helperService.showNotfySuccess(this.$toast, 'All channels have been successfully added.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while trying to add channels. Please refresh the page and try again.')
      } finally {
        this.refreshChannels()
      }
    },
    closeModals() {
      this.$bvModal.hide('modal-add-channels');
      this.$bvModal.hide('modal-remove-channels');
      this.$bvModal.hide('modal-add-all-channels');
      this.$bvModal.hide('modal-remove-all-channels');
    },
    showGroupModal(modalName) {
      this.$bvModal.show(modalName);
    },
  },
};
</script>

<style scoped>

</style>
