<template>
  <section class="text-center">
    <p>{{ title }}</p>
    <p v-if="subtitle" class="font-weight-bolder">{{ subtitle }}</p>
    <p v-if="note">{{ note }}</p>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button variant="success" @click="$emit('ok', true)">OK</b-button>
    </section>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: false,
      type: String,
      default: '',
    },
    note: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>
