import axios from '@axios';
import HelperService from '@/services/HelperService';

const baseUrl = '/groups';

export default {
  getGroups() {
    return axios.get(`${baseUrl}`);
  },
  createGroup(group) {
    return axios.post(`${baseUrl}`, group);
  },
  getGroup(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  deleteGroup(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  updateGroup(uid, groupDto) {
    return axios.put(`${baseUrl}/${uid}`, groupDto);
  },
  syncGroup(uid, groupDto) {
    return axios.post(`${baseUrl}/${uid}/sync`, groupDto);
  },
  getYoutubeChannels(uid) {
    return axios.get(`${baseUrl}/${uid}/youtube-channels`);
  },
  addYoutubeChannels(uid, channelIdsDto, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.put(`${baseUrl}/${uid}/youtube-channels${queryString}`, channelIdsDto);
  },
  addAllYoutubeChannels(uid,) {
    return axios.put(`${baseUrl}/${uid}/youtube-channels`, { youtube_channel_ids: [0] });
  },
  removeYoutubeChannels(uid, channelIdsDto) {
    return axios.post(`${baseUrl}/${uid}/youtube-channels`, channelIdsDto);
  },
  removeAllYoutubeChannels(uid) {
    return axios.post(`${baseUrl}/${uid}/youtube-channels`, { youtube_channel_ids: [0] });
  },
  getNewsFeeds(uid) {
    return axios.get(`${baseUrl}/${uid}/news-feeds`)
  },
  addNewsFeeds(uid, newsFeedIdsDto) {
    return axios.put(`${baseUrl}/${uid}/news-feeds`, newsFeedIdsDto);
  },
  addAllNewsFeeds(uid) {
    return axios.put(`${baseUrl}/${uid}/news-feeds`, { news_feed_ids: [0] })
  },
  removeNewsFeeds(uid, newsFeedIdsDto) {
    return axios.post(`${baseUrl}/${uid}/news-feeds`, newsFeedIdsDto);
  },
  removeAllNewsFeeds(uid) {
    return axios.post(`${baseUrl}/${uid}/news-feeds`, { news_feed_ids: [0] });
  },
};
