import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/youtube/channels';

export default {
  getChannels(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getChannel(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
};
