<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import helperService from "@/services/HelperService";
import AddNewsFeedsModal from "@/views/groups/viewGroup/sections/viewNewsFeeds/AddNewsFeedsModal";
import AddAllNewsFeedsModal from "@/views/groups/viewGroup/sections/viewNewsFeeds/AddAllNewsFeedsModal";
import DeleteModal from "@/components/modals/DeleteModal";
import GroupService from "@/services/GroupService";
import groupService from "@/services/GroupService";

export default {
  name: "GroupNewsFeeds",
  components: {
    BCardActions,
    AddNewsFeedsModal,
    AddAllNewsFeedsModal,
    DeleteModal,
  },
  props: {
    group: {
      required: true,
      type: Object,
    },
  },
  data() {
    const fields = [
      {key: "name", label: "Name", sortable: true},
      {key: "url", label: "URL"},
    ]
    if (this.$can("Update", "Group")) {
      fields.push({
        key: "actions",
        label: "Remove",
        class: "text-right",
      });
    }
    return {
      feeds: [],
      selectedFeeds: [],
      fields,
      addNewsFeedsModalId: "add-news-feeds-modal",
      addAllModalId: "add-all-news-feed-modal",
      deleteModalId: "delete-news-feed-modal",
    };
  },
  mounted() {
    this.getNewsFeeds();
  },
  methods: {
    async getNewsFeeds() {
      try {
        const {data} = await groupService.getNewsFeeds(this.group.uid);
        this.feeds = data;
      } catch (error) {
        //console.error("Error in getNewsFeeds: ", error);
        helperService.showNotfyErr(this.$toast, "Error fetching news feed, please refresh and try again.")
      }
    },
    async addAllNewsFeeds() {
      try {
        await GroupService.addAllNewsFeeds(this.group.uid);
        helperService.showNotfySuccess(this.$toast, "Successfully added all feeds");
        this.refreshFeeds();
      } catch (e) {
        //console.error("Error in addAllNewsFeeds: ", e);
        helperService.showNotfyErr(this.$toast, "Error adding all feeds");
      }
    },
    async removeAllFeeds() {
      try {
        const feedsToDelete = [];
        for (const feed of this.feeds) {
          feedsToDelete.push(feed.id);
        }
        await GroupService.removeAllNewsFeeds(this.group.uid);
        helperService.showNotfySuccess(this.$toast, "Successfully removed feeds");
        this.refreshFeeds();
      } catch (error) {
        //console.error("Error in removeSelectedFeeds: ", error);
        helperService.showNotfyErr(this.$toast, "Error removing feeds");
      }
    },
    async removeSelectedFeeds() {
      try {
        const dto = {
          news_feed_ids: this.selectedFeeds,
        };
        await GroupService.removeNewsFeeds(this.group.uid, dto);
        helperService.showNotfySuccess(this.$toast, "Successfully removed feeds");
        this.refreshFeeds();
      } catch (error) {
        //console.error("Error in removeSelectedFeeds: ", error);
        helperService.showNotfyErr(this.$toast, "Error removing feeds");
      }
    },
    refreshFeeds() {
      this.selectedFeeds = [];
      this.closeModals();
      this.getNewsFeeds();
    },
    onCheckboxClick(feedId) {
      this.selectedFeeds = this.selectedFeeds.includes(feedId)
        ? this.selectedFeeds.filter(id => id !== feedId)
        : [...this.selectedFeeds, feedId];
    },
    closeModals() {
      this.$bvModal.hide(this.addNewsFeedsModalId);
      this.$bvModal.hide(this.addAllModalId);
      this.$bvModal.hide(this.deleteModalId);
    },
    showModal(id) {
      this.$bvModal.show(id);
    }
  }
};
</script>

<template>
  <div id="group-news-feeds-page">
    <b-card-actions ref="groupNewsFeedsCard" action-collapse title="News Feeds" no-body>
      <b-card-body class="d-flex flex-column">
        This table shows the News Feeds the group has access to.
        <b-row v-if="$can('Update', 'Group')" class="d-flex justify-content-end mt-1 mx-0">
          <b-btn
            v-if="selectedFeeds.length"
            variant="outline-danger"
            class="mr-1"
            @click="showModal(deleteModalId)"
          >
            Remove Selected Feeds
          </b-btn>
          <b-btn
            v-if="feeds.length"
            variant="danger"
            class="mr-1"
            @click="showModal(deleteModalId)"
          >
            Remove All Feeds
          </b-btn>
          <b-btn
            variant="outline-primary"
            class="mr-1"
            @click="showModal(addAllModalId)"
          >
            Add All News Feeds
          </b-btn>
          <b-btn
            variant="primary"
            @click="showModal(addNewsFeedsModalId)"
          >
            Add News Feeds
          </b-btn>
        </b-row>
      </b-card-body>
      <b-table
        class="data-table mb-0"
        hover
        :items="feeds"
        :fields="fields"
        fixed
      >
        <template #cell(name)="data">
          <div v-b-tooltip.hover="data.item.name" class="multiline-ellipsis">
            {{ data.item.name }}
          </div>
        </template>
        <template #cell(url)="data">
          <div v-b-tooltip.hover="data.item.url" class="multiline-ellipsis">
            {{ data.item.url }}
          </div>
        </template>
        <template #cell(actions)="data">
          <b-form-checkbox
            v-if="$can('Update', 'Group')"
            :checked="selectedFeeds.includes(data.item.id)"
            group
            class="float-right my-1"
            @change="onCheckboxClick(data.item.id)"
            @click.native.stop
          />
        </template>
      </b-table>

      <b-row v-if="!feeds.length" class="mx-1">
        <b-col cols="12" class="mt-1">
          <p>No feeds available...</p>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal :id="addNewsFeedsModalId" title="Add News Feeds" hide-footer size="lg" body-class="p-0">
      <add-news-feeds-modal :group="group" @refresh="refreshFeeds" @close="closeModals" />
    </b-modal>

    <b-modal :id="addAllModalId" title="Add All News Feeds" hide-footer>
      <add-all-news-feeds-modal title="Are you sure?" @refresh="refreshFeeds" @close="closeModals"
                                @ok="addAllNewsFeeds"
      />
    </b-modal>

    <b-modal :id="deleteModalId" title="Remove " hide-footer>
      <delete-modal
        v-if="selectedFeeds.length || feeds.length"
        title="Are you sure you wish to remove these feeds?"
        @close="closeModals"
        @delete="selectedFeeds.length ? removeSelectedFeeds() : removeAllFeeds()"
      />
    </b-modal>
  </div>
</template>

<style scoped>
.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
