import axios from "@axios";

const baseURL = function (groupUid) {
  return `/groups/${groupUid}/application-features`
}

export default {
  list(groupUid) {
    return axios.get(baseURL(groupUid));
  },
  create(groupUid, dto) {
    return axios.post(baseURL(groupUid), dto);
  },
  delete(groupUid, id) {
    return axios.delete(`${baseURL(groupUid)}/${id}`)
  }
}
