import HelperService from "@/services/HelperService";
import axios from "@axios";

const baseUrl = "/news-feeds";

export default {
  async listNewsFeeds(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return await axios.get(`${baseUrl}${queryString}`)
  },
}
