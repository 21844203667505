





























import TopActivities from "@/components/charts/TopActivities.vue";
import DateRangeSelector from "@/components/charts/DateRangeSelector.vue";
import TopTags from "@/components/charts/TopTags.vue";
import Logins from "@/components/charts/Logins.vue";

export default {
  components: {TopTags, TopActivities, DateRangeSelector, Logins},
  props: {
    group: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      from: new Date(new Date().setDate(new Date().getDate() -  7)).toDateString(),
      to: new Date().toDateString(),
    }
  },
  computed: {
    fromDateISOString() {
      let date = new Date(this.from);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
    toDateISOString() {
      let date = new Date(this.to);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
  },
  methods: {
    updateDateRange(from, to) {
      this.from = from
      this.to = to
    }
  }
}
